.NumberMainPage{ 
    text-align: center;
}

.NumberMainPage h2 {
    color: #333333;
    font-family: "Lato", Helvetica;
    font-size: 22px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
}

.NumberMainPage label {
    color: #4c4c4c;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
}

.NumberMainPage .inputFrames,
.NumberMainPage .dateFrame {
    width: 393px; /* Adjust this width as needed */
    max-width: 95%;
    margin-bottom: 20px;
}

.NumberMainPage .inputFrames {
    text-align: left;
    margin: 0 auto;
}

.NumberMainPage .input{
    width: 97%;
    height: 40px;
    background-color: transparent;
    border: 1px solid;
    border-color: #1d7cbc;
    border-radius: 6px;
    outline: none; /* Removes the default blue outline when focused */
}

.NumberMainPage .input::-webkit-inner-spin-button,
.NumberMainPage .input::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.NumberMainPage .dateFrame{
    width: 393px;
    text-align: left;
    margin: 0 auto;
}

.NumberMainPage .date{
    width: 98%;
    height: 36px;
    background-color: transparent;
    border: 1px solid;
    border-color: #1d7cbc;
    border-radius: 6px;
}
