.TypePage{ 
    text-align: center;
}

.TypePage h2 {
    color: #333333;
    font-family: "Lato", Helvetica;
    font-size: 22px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
}

.TypePage label {
    color: #4c4c4c;
    font-family: "Lato", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
}

.TypePage .inputFrames,
.TypePage .selectFrame {
    width: 393px; /* Adjust this width as needed */
    max-width: 95%;
    margin-bottom: 20px;
}

.TypePage .inputFrames {
    text-align: left;
    margin: 0 auto;
}

.TypePage .input{
    width: 98.5%;
    height: 96px;
    background-color: transparent;
    border: 1px solid;
    border-color: #1d7cbc;
    border-radius: 6px;
    outline: none; /* Removes the default blue outline when focused */
}

.TypePage .selectFrame{
    width: 393px;
    text-align: left;
    margin: 0 auto;
}

.TypePage .select{
    width: 100%;
    height: 36px;
    background-color: transparent;
    border: 1px solid;
    border-color: #1d7cbc;
    border-radius: 6px;
    opacity: 0.5;
}

