.GetStartedPage {
    margin: 5%;
    text-align: center;
}

.GetStartedPage .logo {
    height: 200px;
    object-fit: cover;
    width: 200px;
}

.GetStartedPage h1{
    color: #333333;
    font-family: "Lato";
    font-size: 34px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: normal;
}

.GetStartedPage p{
    color: #4c4c4c;
    font-family: "Lato";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    width: 256px;
    margin: 0 auto;
}